
.css-u9gry2-MuiModal-root-MuiDialog-root .MuiPaper-root {
    overflow: visible !important;
} 

.lead-details-lp-content {
    width: 900px !important;
    height: 547px !important;
    border-radius: 18px;
    background: white;
    padding: 0 !important;
    .radio-label {
        @media screen and (max-width: 800px) {
            font-size: 14px !important;
        }
    }
    @media screen and (max-width: 800px) {
        width: 100% !important;
        height: 392px !important;
        overflow-y: hidden !important;
    }
    .lead-details-lp-hero {
        width: 100%;
        height: 82px;
        background: #f1ae13;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: #fff;
        font-weight: 700;
        font-size: 22px;
        line-height: 116%;
        font-family: Plus Jakarta Sans !important;
        @media screen and (max-width: 800px) {
            font-size: 16px;
            height: 46px !important;
        }
        .dialog-toggle-btn {
            position: fixed;
            transform: translate(27.37em,-1.5em);
           
            cursor: pointer;
            //color: white;
            font-weight: 600;
            color: #000;
            background: #00000029;
            font-weight: 900;
            font-size: 16px;
            border-radius: 50px;
            border: 1px solid #00000029;
            padding: 20px 24px;
            @media screen and (max-width: 800px) {
                padding: 13px 18px;
                transform: translate(8.85em,-0.85em);
            }
        }
    }
    .lp-p-popup {
        display: flex;
        justify-content: center;
        padding-left: 1.3em;
        overflow-x: hidden;
        .submit-btn {
            width: 309px;
            height: 46px !important;
            border-radius: 50%;
            font-weight: 800 !important;
            font-family: Plus Jakarta Sans !important;
            text-transform: capitalize !important;
            @media screen and (max-width: 800px) {
                border-radius: 12px !important;
                width: 269px !important;
                height: 34px !important;
            }
        }
        .main-mlp-form {
            width: 380px;
            height: 465px;
            @media screen and (max-width: 800px) {
                width: 309px;
                height: 392px;
            
            }
            .form-input input {
                @media screen and (max-width: 800px) {
                    width: 100%;
                }
            }
            .mlp-form-header {
                height: 100px;
                width: 380px;
                margin: -1em;
                margin-bottom: 2em;
                display: none;
            }
            .optin-container {
                margin-top: 4em;
                @media screen and (max-width: 800px) {
                    margin-top: 1em;
                }
            }
            .form-referral {
                display: none;
            }
            .mlp-radio-group {
                margin-bottom: 4em;
                @media screen and (max-width: 800px) {
                    margin-bottom: 2em;
                }
            }
            .submit-btn-helper-txt {
                display: none;
            }
            .submit-btn-helper-txt-2 {
                color: #FF612C;
                font-family: Plus Jakarta Sans !important;
                font-size: 18px;
                font-weight: 600;
                line-height: 133%;
                text-align: center;
                margin-bottom: 1em;
                margin-top: 3em;
                @media screen and (max-width: 800px) {
                    margin-top: 0em;
                    font-size: 14px;
                }
            }
        }  
       
        .mlp-form-non-brand-head {
            color: #fff;
            font-family: Plus Jakarta Sans !important;
            font-size: 18px;
            font-weight: 700;
            line-height: 116%;
        }
        .mlp-form-non-brand-desc {
            color: #fff;
            font-family: Plus Jakarta Sans !important;
            font-size: 16px;
            font-weight: 700;
            line-height: 116%;
            margin-top: 0.4em;
        }
        .form-content {
            width: 100%;
            height: 100%;
            padding: 1em;
            box-shadow: none;
            @media screen and (max-width: 800px) {
                height: auto;
                padding: 0em;
            }
        }
        .form-input-phone {
            @media screen and (max-width: 800px) {
                margin-top: 1em;
            }
        }
        .form-input {
            margin-top: 1em;
            @media screen and (max-width: 800px) {
                margin-top: 0.5em;
                width: 280px;
            }
        }

        .mlp-submit {
            width: 301px;
            margin-left: 1.5em;
        }

        .mlp-form-bg {
            background: none;
        }
        .lp-dmc-card {
            width: 798px;
            height: 565px;
            border-radius: 10px;
            box-shadow: 0px 4px 46px 0px rgba(0, 0, 0, 0.25);
            background: url(../../../../Images/landingPageImages/dmc-cardBg.webp);
            background-position: top right;
            background-repeat: no-repeat;
            background-size: 250px 237px;
            padding: 2em 1.2em 1em 2em;
            @media screen and (max-width: 800px) {
                background: none;
                padding: 1.5em 0.2em;
                width: 300px;
                height: auto;
                display: flex;
                flex-direction: column;
                align-items: center;
                background: linear-gradient(to bottom, transparent 0%, transparent calc(100% - 425px), #F3F0F0 calc(100% - 425px), #F3F0F0 100%);

            }
            .lp-dmc-head {
                color: #505050;
                font-family: Plus Jakarta Sans !important;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 133%;
                @media screen and (max-width: 800px) {
                    font-size: 18px;
                }
            }
            .lp-dmc-row {
                display: flex;
                justify-content: space-between;
                @media screen and (max-width: 800px) {
                    flex-direction: column-reverse;
                }
                .ptop-1 {
                    padding-top: 1em;
                }
                .dmc-pc-img {
                    width: 307px;
                    height: 170px;
                     margin-top: 1em;
                     
                     @media screen and (max-width: 800px) {
                        margin-top: 0em;
                        margin-bottom: 1em;
                        width: 255px;
                        height: 140px;
                    }
                }
                .lp-dmc-li {
                    display: flex;
                    margin-top: 1em;
                    .green-tick {
                        width: 33px;
                        height: 29px;
                        margin-right: 1em;
                    }
                  
                }
                .lp-dmc-li-title {
                    color: #f1ae13;
                    font-family: Plus Jakarta Sans !important;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 116%;
                    @media screen and (max-width: 800px) {
                        font-size: 12px;
                    }
                }
                .lp-dmc-li-desc {
                    color: #505050;
                    font-family: Plus Jakarta Sans !important;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 116%;
                    width: 366px;
                    @media screen and (max-width: 800px) {
                        font-size: 12px;
                        max-width: 230px;
                    }
                }
                .dmc-b-l {
                    padding-left: 3.2em;
                    border-left: 1.5px solid #e6e6e6;
                    @media screen and (max-width: 800px) {
                        padding-left: 0em;
                        border: none;
                        padding-top: 1.5em;
                        border-top: 1.5px solid #e6e6e6;
                        margin-top: 1.5em;
                        width: 260px;
                    }
                }
                .lp-dmc-hr {
                    width: 1px;
                    height: 145px;
                    background-color: #e6e6e6;
                    margin-right: 2em;
                    @media screen and (max-width: 800px) {
                        display: none;
                    }
                }
                .dmc-full-btn {
                    width: 92px;
                    height: 21px;
                    background-color: #3751FF;
                    color: white;
                    font-family: Plus Jakarta Sans !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50px;
                    font-size: 14px;
                    @media screen and (max-width: 800px) {
                    font-size: 14px;
                    }
                }
                .lp-dmc-card-title-2 {
                    color: #505050;
                    font-family: Plus Jakarta Sans !important;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 133%;
                    margin-top: 0.5em;
                    margin-bottom: 1em;
                }
            }
            .lp-dmc-row:last-of-type {
                @media screen and (max-width: 800px) {
                    flex-direction: column;
                    margin-top: 1em;
                }
            }
        }
        .lp-dmc-download {
            font-family: Plus Jakarta Sans !important;
            font-size: 16px;
            font-style: normal;
            font-weight: 800;
            color: white;
            width: 272px;
            height: 46px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #f1ae13;
            border-radius: 100px;
            margin-top: 3em;
            text-align: center;
            border: none;
            position: absolute;
            left: 23%;
            cursor: pointer;
            @media screen and (max-width: 800px) {
                left: 0%;
                position: relative;
                width: 269px;
                height: 34px;
                font-size: 14px;
                border-radius: 12px;
            }
        }
    }
}