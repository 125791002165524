.lp-placements {
  background: url(../../../../Images/csm/admissionBg.png);
  background-size: cover;
  background-size: 100% 808px;
  background-position: center;
  background-repeat: no-repeat;
  height: 785px;
  width: 100%;
  margin-top: -1.2em;
  padding: 1em 6em;
  padding-top: 6em;
  @media screen and (max-width: 800px) {
    height: auto;
    background: none;
    padding: 0;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    margin: 1.5em 0;
  }
  .lp-p-sub-head {
    color: #f1ae13;
    font-family: Plus Jakarta Sans !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 133%;
    @media screen and (max-width: 800px) {
      font-size: 16px;
    }
  }
  .l-p-head {
    color: #505050;
    font-family: Plus Jakarta Sans !important;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 143%;
    @media screen and (max-width: 800px) {
      font-size: 20px;
      padding: 0.5em 0;
    }
  }
  .lp-p-underline {
    height: 8px;
    width: 292px;
    @media screen and (max-width: 800px) {
      width: 153px;
      height: 5px;
    }
  }
  .lp-det-img-flex {
    @media screen and (max-width: 800px) {
      display: flex;
      align-items: center;
    }
  }
  .lp-p-flex {
    display: flex;
    justify-content: space-between;
    margin-top: 2em;
    max-width: 1250px;
    @media screen and (max-width: 800px) {
      flex-direction: column;
    }
  }
  .lp-p-card-det {
    display: flex;
    width: 90%;
    margin: 1em 0;
    margin-left: 1em;
    @media screen and (max-width: 800px) {
      margin-left: 0em;
      width: 100%;
    }
    .lp-p-card-det-content {
      width: 220px;
    }
  }
  .card-det-icon {
    max-width: 30px;
    height: 30px;
    @media screen and (max-width: 800px) {
      max-width: 18px;
      width: auto;
      height: 18px;
      margin-right: 10px;
    }
  }

  .mb-1 {
    margin-bottom: 1em;
    @media screen and (max-width: 800px) {
      margin-bottom: 0em !important;
    }
  }
  .lp-pin1 {
    width: 27px;
    height: 54px;
    position: absolute;
    transform: translate(8em, -3em);
    @media screen and (max-width: 800px) {
      transform: translate(14.5em, -2.4em);
      width: 22px;
      height: 44px;
    }
  }
  .lp-pin2 {
    width: 38px;
    height: 50px;
    position: absolute;
    transform: translate(17em, -3em);
    @media screen and (max-width: 800px) {
      transform: translate(14.4em, -3em);
      width: 29px;
      height: 37px;
    }
  }
  .lp-pin3 {
    width: 27px;
    height: 45px;
    position: absolute;
    transform: translate(18em, -2.5em);
    @media screen and (max-width: 800px) {
      transform: translate(14.5em, -2.5em);
      width: 20px;
      height: 35px;
    }
  }
  .icon-wide {
    max-width: 46px;
    height: 30px;
    @media screen and (max-width: 800px) {
      max-width: 29px;
      height: 14px;
    }
  }
  .avg-card-det-icon {
    max-width: 32px;
    height: 30px;
    @media screen and (max-width: 800px) {
      max-width: 20px;
      height: 18px;
    }
  }
  .card-det-head {
    color: #f1ae13;
    font-family: Plus Jakarta Sans !important;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 153%;
    @media screen and (max-width: 800px) {
      font-size: 18px;
    }
  }
  .card-det-desc {
    color: #505050;
    font-family: Plus Jakarta Sans !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 153%;
    @media screen and (max-width: 800px) {
      font-size: 12px;
    }
  }
  .lp-p-card {
    width: 448px;
    height: 468px;
    background: url(../../../../Images/landingPageImages/placementCardBg.webp);
    background-size: cover;
    padding: 2em 1.5em;
    background-size: 475px 465px;
    display: flex;
    flex-direction: column;
    align-items: center;
    //scale: 1.05;
    @media screen and (max-width: 800px) {
      scale: 1.04;
      align-items: flex-start;
      width: 326px;
      height: 320px;
      transform: translateX(-0.2em);
      margin-bottom: 0.4em;
      padding-left: 2em;
      background-size: 330px 325px;
      background-repeat: no-repeat;
    }
    .lp-p-card-head {
      color: #505050;
      font-family: Plus Jakarta Sans !important;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 133%;
      @media screen and (max-width: 800px) {
        font-size: 18px;
      }
    }
    .lp-p-disc {
      font-family: Plus Jakarta Sans !important;
      font-size: 14px;
      font-style: italic;
      font-weight: 400;
      color: #7e7e7e;
      line-height: 153%;
      margin-top: 2em;
      @media screen and (max-width: 800px) {
          margin-top: 1em;
          font-size: 12px;
      }
    }
  }
  .lp-p-card-img {
    margin-top: 3em;
    @media screen and (max-width: 800px) {
      margin-top: 1.4em;
        height: 195px;
        width: 255px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }
  }
  .lp-det-img {
    width: 315px;
    margin-bottom: 3.4em;
    @media screen and (max-width: 800px) {
      max-width: 228px;
      margin-bottom: 1.4em;
    }
  }
  .lp-det-img-2 {
    width: 334px;
    height: 276px;
    margin-top: 1em;
    @media screen and (max-width: 800px) {
      width: 250px;
      height: 182px;
      margin-left: 0.4em;
    }
  }
  .lp-p-card-2 {
    align-items: flex-start;
    padding-left: 3em;
    @media screen and (max-width: 800px) {
      padding-left: 2em;
    }
  }
}
