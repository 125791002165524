@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;600;700;800&display=swap');
*{
    font-family: Plus Jakarta Sans;
    box-sizing: border-box;
}
.landing-page {
    width: 100%;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 800px) {
    align-items: center;
    }
    .logo{
        width: 111px;
        height: 65px;
        margin-top: 1em;
        margin-left: 6em;
        @media screen and (max-width: 800px) {
            margin-left: 0em;
            display: none;
        }
    }
    .curiculum-divider {
        width: 100%;
        height: 102px;
        background: url(../../Images/landingPageImages/RectangleBg.png);
        background-size: cover;
        display: flex;
        padding: 0 3.5em;
        justify-content: space-between;
        align-items: center;
        color: #fff;
        font-family: Plus Jakarta Sans !important;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 133%;
        @media screen and (max-width: 800px) {
            display: none;
        }
        .c-d-btn {
            border: none;
            font-weight: 800;
            background: #ffffff;
            border-radius: 100px;
            color: #f1ae13;
            font-size: 16px;
            width: 272px;
            height: 47px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: Plus Jakarta Sans !important;
            cursor: pointer;
        }
    }
    .curiculum-divider-2 {
        width: 100%;
        height: 167px;
        background: url(../../Images/landingPageImages/RectangleBg.png);
        background-size: cover;
        display: flex;
        flex-direction: column;
        padding: 0 6em;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-family: Plus Jakarta Sans !important;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 133%;
        @media screen and (max-width: 800px) {
            font-size: 22px;
            height: 190px;
            text-align: center;
            padding: 0 1em;
        }
        .c-d-btn {
            border: none;
            font-weight: 800;
            background: #ffffff;
            border-radius: 100px;
            color: #f1ae13;
            font-size: 16px;
            width: 272px;
            height: 47px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin-top: 2em;
            font-family: Plus Jakarta Sans !important;
            @media screen and (max-width: 800px) {
                font-size: 12px;
                width: 219px;
                height: 34px;
                font-weight: 700;
            }
        }
    }
    .note-from-founders {
        @media screen and (max-width: 800px) {
          padding-top: 1em;
        }
    }
    .note-from-founders .notes {
        @media screen and (max-width: 800px) {
        text-align: justify;
        margin-left: 0;
        width: 300px;
        }
    }
    .lp-page-footer {
        z-index: 20;
        width: 100%;
        height: 102px;
        position: fixed;
        bottom: 0;
        background: #3751FF;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media screen and (min-width: 800px) {
            display: none;
        }
        .lp-footer-txt {
            color: #fff;
            font-family: Plus Jakarta Sans !important;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 133%;
            text-transform: capitalize;
            margin-bottom: 0.6em;
            animation: enrollAnimation 1s infinite;
         }
        .footerbtn {
            color: #3751FF;
            background-color: #fff;
            width: 166px;
            height: 34px;
            text-align: center;
            border-radius: 100px;
            border: none;
            font-weight: 700
        }
    }
}

@keyframes enrollAnimation {
    0% {
        opacity: 0.5;
        transform: scale(0.5);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}