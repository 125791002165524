.alumni .role-head{
    font-weight: 700;
font-size: 28px;
line-height: 37px;
text-align: center;
color: #7E7E7E;
@media screen and (max-width: 800px) {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    padding: 0 0.5em;
     max-width: 70%;
}
}
.new-underline {
    width: 153px;
}
.alumni{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4em;
    hr{
        width: 100%;
        margin: 1em 0;
        border: .5px solid #F1AE13;
        background-color: #F1AE13;
        opacity: 0.3;
    }

 .content{
    display: flex;
    justify-content: space-between;
    width: 85%;
    max-width: 1200px;
    align-items: center;
    margin-top: 3em;
    margin-bottom: -1em;
    @media screen and (max-width: 1200px) {
        width: 90%;
        flex-direction: column;
        justify-content: center;
        margin: 1em;

    }
 }
}

.alumni-details{
    max-width: 500px;
    margin-left: 2em;
    @media screen and (max-width: 1200px) {
        max-width: 70%;
        margin-top: 1em;
        margin-left: 0;
    }
    @media screen and (max-width: 1200px) {
        max-width: 100%;
    }
}
.alumni-video{
    @media screen and (max-width: 800px) {
    width: 100%;
    height: 180px;
    margin: 0;
    }
    iframe{
       width: 644px;
       height: 360px;
       border-radius: 10px;
       @media screen and (max-width: 800px) {
        width: 100%;
        height: 180px;
        margin-bottom: 2em;
       }
    }
}



.name{
    font-weight: 700;
    font-size: 24px;
    line-height: 34.5px;
    color: #F1AE13;
    @media screen and (max-width: 800px) {
        font-size: 16px;
        line-height: 23px;
    }
}

.role{
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #7E7E7E;
    margin-bottom: .5em;
    @media screen and (max-width: 800px) {
        font-size: 12px;
        line-height: 17.2px;
    }
}


.specialisation{
    font-weight: 400;
font-size: 14px;
line-height: 14px;
color: #7E7E7E;
margin-bottom: .5em;
@media screen and (max-width: 800px) {
    font-size: 12px;
    line-height: 17.2px;
 }
}

.testimonial{
    font-style: italic;
    font-weight: 500;
    font-size: 18px;
    line-height: 26.4px;
    color: #7E7E7E;

    @media screen and (max-width: 800px) {
        font-size: 12px;
        line-height: 17.6px;
    }
}

.mlp-main .placement-slider .slick-dots{
    li{
        button{
            @media screen and (min-width: 800px){
           padding: 0 1.2em;
           margin: 2px;
            background-color: #7E7E7E;
            color: #7E7E7E;
            cursor: pointer;
            opacity: 1 !important;
         }
         margin: 0 .2em;
        }
    }
        .slick-active{
            button{
                @media screen and (min-width: 800px){
            padding: 0 3em;
            opacity: 1 !important;
            }
         }
        }
}