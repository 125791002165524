.lp-hero-sect {
  max-width: 1300px;
  margin: 1em 6em;
  display: flex;
  justify-content: space-between;
  .dots-middle-lp {
    width: 580px;
    height: 175px;
    position: absolute;
    //transform: translate(1em,1em);
    top: 0;
    right: 40%;
    @media screen and (max-width: 800px) {
      display: none;
    }
  }
  .dots-right-lp {
    width: 243px;
    height: 386px;
    position: absolute;
    //transform: translate(1em,1em);
    right: 0;
    top: 0;
    @media screen and (max-width: 800px) {
      display: none;
    }
  }
  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    margin: 1em;
  }
  .lp-hero-img {
    width: 622px;
    height: 430px;
    transform: translate(3em, -3em);
    @media screen and (max-width: 800px) {
        transform: translate(1em, 0em);
      height: 237px;
      width: 320px;
    }
  }
  .lp-hero-text-sec {
    display: flex;
    flex-direction: column;
    padding-top: 2em;
    @media screen and (max-width: 800px) {
      align-items: center;
    }
    .lp-mlp-logo {
      width: 271px;
      height: 63px;
      @media screen and (max-width: 800px) {
        width: 219px;
        height: 50px;
      }
    }
    .lp-hero-head {
      color: #f1ae13;
      font-family: Plus Jakarta Sans !important;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: 120.7%;
      max-width: 550px;
      margin-top: 1.2em;
      @media screen and (max-width: 800px) {
        margin-top: 1em;
        font-size: 22px;
        text-align: center;
        max-width: 328px;
      }
    }
    .lp-hero-desc {
      margin: 0.5em 0;
      color: #505050;
      font-family: Plus Jakarta Sans !important;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 165.7%;
      @media screen and (max-width: 800px) {
        font-size: 14px;
        text-align: center;
        max-width: 314px;
        margin-top: 1em;
        line-height: 120.7%;
        margin-top: 1.5em;
      }
    }
    .lp-learn {
      width: 166px;
      height: 46px;
      background-color: #f1ae13;
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-family: Plus Jakarta Sans !important;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      border: none;
      margin-top: 1em;
      cursor: pointer;
      @media screen and (max-width: 800px) {
        width: 269px;
        height: 34px;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        border-radius: 12px;
      }
    }
    .lp-heo-flex {
      display: flex;
      align-items: center;
      margin: 0.5em 0;

      span {
        color: #505050;
        font-family: Plus Jakarta Sans !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 120.7%;
        @media screen and (max-width: 800px) {
          font-family: Plus Jakarta Sans !important;
          font-size: 13px;
        }
      }
      @media screen and (max-width: 800px) {
        flex-direction: column;
        text-align: center;
        gap: 1em;
      }
      .flex-normal-txt {
        font-weight: 400;
        b {
          @media screen and (max-width: 800px) {
            font-weight: 400;
          }
        }
      }
    }
    .icon-lp {
      width: 13px;
      height: 12px;
      margin-right: 4px;
    }
    .caseIcon {
      width: 17px !important;
      height: 14px !important;
    }
    .icon-bg {
      width: 26px !important;
      height: 13px !important;
      @media screen and (max-width: 800px) {
        max-width: 26px;
      }
    }
    .icon-sm {
      max-width: 11px;
      height: 17px;
      @media screen and (max-width: 800px) {
        max-width: 11px;
      }
    }

    .lp-bar {
      color: #f1ae13;
      font-size: 16px;
      margin: 0 0.6em;
      @media screen and (max-width: 800px) {
        display: none;
      }
    }
  }
}
