.lp-career {
  width: 100%;
  padding: 4em 0;

  @media screen and (max-width: 800px) {
    padding: 0;
    max-width: 100%;
  }
  .pc-none{
    @media screen and (min-width: 800px) {
      display: none;
    }
  }
  @media screen and (min-width: 900px) {
    .pc-hide {
        display: none;
    }
}
  .lp-career-hero {
    margin-left: 6em;
    margin-bottom: 3em;
    @media screen and (max-width: 800px) {
      margin: 2em 2em;
    }
  }
  .lp-c-sub-head {
    color: #f1ae13;
    font-family: Plus Jakarta Sans !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 133%;
    @media screen and (max-width: 800px) {
      font-size: 16px;
      padding: 0;
    }
  }
  .l-c-head {
    color: #505050;
    font-family: Plus Jakarta Sans !important;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 143%;
    @media screen and (max-width: 800px) {
      font-size: 20px;
      padding: 0;
      margin-top: 0.4em;
      width: 260px;
    }
  }
  .lp-c-underline {
    height: 8px;
    width: 292px;
    @media screen and (max-width: 800px) {
      width: 203px;
      padding: 0;
      height: 7px;
    }
  }

  .l-c-desc {
    color: #505050;
    font-family: Plus Jakarta Sans !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 143%;
    margin-top: 0.5em;
    @media screen and (max-width: 800px) {
      margin-top: 1em;
      padding: 0;
      font-size: 14px;
      width: 288px;
      line-height: 120%;
    }
  }
  .csm-main-placements .placements-container .tables-container .table-section table, .csm-main-placements .placements-container .tables-container .table-section td, .csm-main-placements .placements-container .tables-container .table-section th {
    @media screen and (max-width: 800px) {
      padding: 0 !important;
    }
  }
  .tables-container {
    display: flex;
    flex-direction: column;
    align-items: center;
   
    .menu {
      max-width: 820px;
      display: flex;
      justify-content: space-between;
      border-radius: 40px;
      background: #f9f9f9;
      box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.12);
      @media screen and (max-width: 900px) {
        width: 97%;
      }
      .menu-item {
        color: #4e4e4e;
        text-align: center;
        font-family: Plus Jakarta Sans !important;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%; /* 21px */
        padding: 1.2em 4.5em;
        cursor: pointer;
        @media screen and (max-width: 900px) {
          padding: 1em 1.7em;
        }
        @media screen and (max-width: 400px) {
          padding: 0.8em 0.6em !important;
          font-size: 16px;
        }
      }
      .active {
        border-radius: 40px;
        border: 1px solid #f1ae13;
        font-family: Plus Jakarta Sans !important;
        color: white;
        background: #f1ae13;
        box-shadow: 0px 4px 15px 0px rgba(241, 174, 19, 0.6),
          0px 4px 4px 0px rgba(0, 0, 0, 0.25),
          0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        @media screen and (max-width: 800px) {
          min-width: 115px;
          box-shadow: 0px 4px 15px 0px rgba(241, 177, 28, 0.6) !important;
        }
      }
    }

    .cover {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media screen and (max-width: 900px) {
        margin-left: 0em;
      }
    }
    .table-section {
      margin-top: 46px !important;
      @media screen and (max-width: 900px) {
        margin-top: 36px !important;
        width: 100%;
        overflow-x: scroll;
      }
    
      table,
      td,
      th {
        border: 1.5px solid #e8e8e8 !important;
        border-collapse: separate;
        border-spacing: 0;
        font-family: Plus Jakarta Sans !important;
        @media screen and (max-width: 900px) {
          border: 0.6px solid #eeee !important;
          padding: 0 0.6em;
        }
      }
      td,
      th {
        padding: 1em 0.74em;
        width: 200px;
        height: 80px;
        @media screen and (max-width: 900px) {
          height: 60px;
        }
      }
      table {
        width: 1065px;
        border-radius: 15px;
        min-height: 500px;
        @media screen and (max-width: 900px) {
          min-height: 330px;
          align-items: flex-start;
          width: 100%;
          overflow-x: hidden;
          margin-left: 1.5em;
        }
      }
      
      .table-section table, .csm-main-placements .placements-container .tables-container .table-section td, .csm-main-placements .placements-container .tables-container .table-section th {
        padding: 0 !important;
      }
      th {
        @media screen and (max-width: 900px) {
          height: 80px !important;
        }
      }
      th img {
        width: 90% !important;
        @media screen and (max-width: 900px) {
          width: 144px !important;
          height: 39px;
        }
      }

      tr > td:first-child {
        color: #4e4e4e;
        font-family: Plus Jakarta Sans !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 133.4%; /* 21.344px */
        text-align: left;
        @media screen and (max-width: 900px) {
          font-size: 12px;
        }
      }
      td {
        color: #4e4e4e;
        text-align: center;
        font-family: Plus Jakarta Sans !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 133.4%; /* 21.344px */
        min-width: 102px;
        .offerText {
          color: #e60200;
          font-family: Plus Jakarta Sans !important;
          text-decoration: line-through;
          margin-right: 5px;
        }
        @media screen and (max-width: 900px) {
          min-width: 91px;
          color: #4e4e4e;
          font-family: Plus Jakarta Sans !important;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 133.4%; /* 16.008px */
        }
      }

      th:first-child {
        @media screen and (max-width: 900px) {
          position: sticky;
          left: 0;
          z-index: 2;
          background-color: white;

          margin-right: 10px;
        }
      }
      td:first-child {
        @media screen and (max-width: 900px) {
          position: sticky;
          left: 0;
          background-color: white;
          z-index: 2;
        }
      }

      th:nth-child(2),
      tr > td:nth-child(2) {
        background: rgba(252, 240, 213, 0.42);
        fill: rgba(241, 174, 19, 0.1);
        stroke-width: 1px;
        stroke: #f1ae13 !important;
        color: #4e4e4e;

        text-align: center;
        font-family: Plus Jakarta Sans !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 133.4%; /* 21.344px */
        filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.1));
        border-right: 2px solid #f1ae13 !important;
        border-left: 2px solid #f1ae13 !important;
        min-width: 180px;
        @media screen and (max-width: 900px) {
          border-radius: 0 !important;
          font-size: 12px;
          border-right: 1px solid #f1ae13 !important;
          border-left: 1px solid #f1ae13 !important;
          min-width: 180px;
        }
      }
      th:nth-child(2) {
        background-color: white;
        border-radius: var(--Number, 8px) 8px var(--Number, 0px)
          var(--Number, 0px);
        border: 1px solid #f1ae13;
        width: 250px;
        border-top: 1px solid #f1ae13 !important;
        border-bottom: 1px solid #f1ae13 !important;
        @media screen and (min-width: 900px) {
          border-bottom: 2px solid #f1ae13 !important;
          border-top: 2px solid #f1ae13 !important;
        }
      }
      tr > td:nth-child(2) {
        border-left: 1px solid #f1ae13;
        border-right: 1px solid #f1ae13;
       // box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.1) !important;
      }
      tr:last-child > td:nth-child(2) {
        @media screen and (min-width: 900px) {
          border-bottom: 2px solid #f1ae13 !important;
        }
        border-bottom: 1px solid #f1ae13 !important;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
      td {
        margin: 250px;
      }
      th {
        color: #4e4e4e;
        font-size: 16px;
        font-weight: 700;
        @media screen and (max-width: 900px) {
          font-size: 12px;
        }
      }
      tr > td:first-child {
        background-color: #f9f9f9;
      }
      th:first-child {
        background-color: #f9f9f9;
      }
     
    }
  }
  .tables-container::-webkit-scrollbar {
    display: none !important;
  }
}
.csm-main-placements .placements-container .tables-container .table-section tr > td:first-child  {
  @media screen and (max-width: 900px) {
    width: 109px !important;
    //background-color: #e60200;
    padding-left: 0.6em !important;
  }
}

.pc-hide, .pc-none {
  @media screen and (min-width: 800px) {
    display: none !important;
  }
}

.mobile-hide, .mobile-none {
  @media screen and (max-width: 800px) {
    display: none !important;
  }
}

