.lp-leader {
  width: 100%;
  padding-bottom: 6em;
  overflow-x: hidden;

  @media screen and (max-width: 800px) {
    padding: 0;
    padding-left: 1.5em;
    max-width: 340px;
  }
  .slick-list {
    position: relative;
  }

  .lp-leader-hero {
    margin-left: 6em;
    margin-bottom: 3em;
    @media screen and (max-width: 800px) {
      margin-left: 0em;
    }
  }
  .lp-t-sub-head {
    color: #f1ae13;
    font-family: Plus Jakarta Sans !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 133%;
    @media screen and (max-width: 800px) {
      font-size: 16px;
      padding: 0;
    }
  }
  .l-t-head {
    color: #505050;
    font-family: Plus Jakarta Sans !important;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 143%;
    @media screen and (max-width: 800px) {
      font-size: 20px;
      padding: 0;
      transform: translateY(5px);
      width: 225px;
      margin-bottom: 0.4em;
    }
  }
  .lp-t-underline {
    height: 8px;
    width: 292px;
    @media screen and (max-width: 800px) {
      width: 178px;
      padding: 0;
      height: 7px;
    }
  }
  
  .lp-leader-slider {
    padding-bottom: 2em !important;
    margin: 0em auto;
    padding: 1em 1.5em;
    @media screen and (max-width: 800px) {
      padding: 2em 1.5em;
      transform: translateX(-2.4em);
    }
    .slick-next {
        transform: translate(99em, -13em) !important;
        background: url(../../../../Images/Assets/nextGrey.png) !important;
        background-size: cover !important;
        border: none;
        width: 42px;
        height: 42px;
        text-indent: -9999px; /* Moves the text out of view */
        overflow: hidden;
        white-space: nowrap;
        cursor: pointer;
      }
      .slick-prev {
        transform: translate(-4em, 12em) !important;
        background: url(../../../../Images/csm/new-slick-prev.png) !important;
        background-size: cover !important;
        border: none;
        width: 42px;
        height: 42px;
        text-indent: -9999px; /* Moves the text out of view */
        overflow: hidden;
        cursor: pointer;
        white-space: nowrap;
        z-index: 2;
    }
  }
  .l-t-desc {
    color: #505050;
    font-family: Plus Jakarta Sans !important;
    font-size: 16px;
    font-weight: 400;
    line-height: 143%;
    margin-top: 0.5em;
    @media screen and (max-width: 800px) {
      margin-top: 0.8em;
    }
    .l-t-desc-b {
      color: #505050;
      font-family: Plus Jakarta Sans !important;
      line-height: 143%;
      font-size: 16px;
      font-weight: 700;
      @media screen and (max-width: 800px) {
        font-size: 14px;
        line-height: 120%;
        font-weight: 400;
      }
    }
    @media screen and (max-width: 800px) {
      padding: 0;
      font-size: 14px;
      line-height: 120%;
      max-width: 266px;
    }
  }

  .lp-leader-slider {
    @media screen and (max-width: 800px) {
      display: flex;
    }
    .lp-leader-card {
      width: 268px !important;
      height: 292px;
      box-shadow: 0px 4px 22px 0 rgba(0, 0, 0, 0.08);
      // margin: 0 1em;
      display: flex !important;
      flex-direction: column;
      align-items: center;
      border-radius: 10px;
      background-color: #fff;
      @media screen and (max-width: 800px) {
        //flex-direction: row;
        width: 190px !important;
        height: 247px;
        margin-right: 1.2em;
      }
    .lp-trainer-card-name {
      color: #505050;
      font-family: Plus Jakarta Sans !important;
      font-size: 16px;
      font-weight: 700;
      line-height: 133%;
      margin-top: 0.6em;
      margin-bottom: 0.2em;
      text-transform: uppercase;
    }
    .lp-trainer-card-pos {
      color: #f1ae13;
      font-family: Plus Jakarta Sans !important;
      font-size: 16px;
      font-weight: 500;
      line-height: 133%;
      text-align: center;
      @media screen and (max-width: 800px) {
        font-size: 14px;
        font-weight: 400;
        max-width: 158px;
        text-align: center;
      }
    }
    .lp-trainer-card-desc {
      color: #505050;
      font-family: Plus Jakarta Sans !important;
      font-size: 14px;
      font-weight: 400;
      line-height: 133%;
    }

      .news-card-basl-img {
        height: 116px;
        width: 116px;
        margin-top: -2.5em;
        @media screen and (max-width: 800px) {
          height: 82px;
          width: 82px;
          margin-top: -1.5em;
        }
      }
      .news-card-basl-footer {
        width: 240px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .news-basl-footer-text {
          color: #505050;
          font-family: Plus Jakarta Sans !important;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: 143%;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 0.6em;
          @media screen and (max-width: 800px) {
            padding-top: 0.6em;
            height: 170px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
        }
        .leader-comp-img {
          width: 83px !important;
          height: 65px;
          margin: 1em 0;
          object-fit: contain;
          object-position: center;
          scale: 1.2;
          @media screen and (max-width: 800px) {
            scale: 1;
            margin: 0.4em;
            width: 65px !important;
            height: 55px;
            object-fit: contain;
            object-position: center;
          }
        }
        .margin-less {
          margin: 0.5em 0;
        }
        .l-c-img-containr {
          @media screen and (max-width: 800px) {
           
          }
        }
      }
    }
  }
  .lp-btn-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 1em;
  }
  .popupBtn {
    width: 302px;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: Plus Jakarta Sans !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 143%;
    border: none;
    background: #f1ae13;
    border-radius: 50px;
    cursor: pointer;
    @media screen and (max-width: 800px) {
      display: none;
    }
  }
  .basl-main-placements {
    height: auto;
    margin-left: 5em;
    @media screen and (max-width: 800px) {
      margin-left: 0;
    }
  }
  .lp-mobile-cards-container {
    display: flex;
    align-items: center;
    width: 100%;
    overflow-x: scroll;
    height: auto;
    margin: 0em;
    padding: 0 1em;
    transform: translateY(-1.4em);
    @media screen and (min-width: 800px) {
      display: none;
    }
  }
}

.slick-container {
  width: 100%;
  overflow-x: hidden;
}
.slick-track {
  display: flex;
}