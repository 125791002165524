.lp-about {
    width: 100%;
    margin-top: 4em;
    min-height: 343px;
    padding: 3em 6em;
    background: url(../../../../Images/landingPageImages/aboutBg.webp);
    background-size: cover;
    @media screen and (max-width: 800px) {
        padding: 3em 2em;
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-top: 2em;
        padding-bottom: 9em;
    }
    .lp-about-title {
        color: #505050;
        font-family: Plus Jakarta Sans !important;
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        line-height: 153%;
        @media screen and (max-width: 800px) {
            font-size: 23px;
        }
    }
    .lp-about-underline {
        width: 152px;
        height: 8px;
        margin: 0.5em 0;
        @media screen and (max-width: 800px) {
            width: 92px;
            height: 8px;
        }
    }
    .lp-about-desc {
        color: #505050;
        font-family: Plus Jakarta Sans !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 164%;
        max-width: 1250px;
        @media screen and (max-width: 800px) {
            font-size: 14px;
            max-width: 285px;
            line-height: 120%;
            font-weight: 400;
        }
    }
}