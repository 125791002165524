.otp-verification-container {
    position: relative;
    width: 45%;
    background: #fff;
    box-shadow: 4px 4px 25px rgb(0 0 0 / 10%);
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    @media screen and (max-width: 900px) {
      width: 90%;
      margin-top: 2em;
    }
    .otp-form-header {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 1rem;
      border-bottom: 1px solid #E6E5E5;
      .back-btn {
        position: absolute;
        padding-left: 1rem;
        left: 1rem;
        color: #f1ae13;
        border: none;
        background-color: #fff;
        font-size: 0.875rem;
        font-weight: 600;
        cursor: pointer;
        @media screen and (max-width: 900px) {
          font-size: 0.75rem;
          padding-left: 0.75rem;
        }
      }
      .back-btn::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        transform: translate(0%, 2px);
        width: 0.675rem;
        height: 0.675rem;
        rotate: 45deg;
        border-color: #f1ae13;
        border-left: 2px solid;
        border-bottom: 2px solid;
        @media screen and (max-width: 900px) {
          width: 0.5rem;
          height: 0.5rem;
        }
      }
      .main-header {
        margin: 2rem 0 0.5rem 0;
        font-size: 1.5rem;
        font-weight: 700;
        color: #7E7E7E;
        @media screen and (max-width: 900px) {
          font-size: 1rem;
        }
      }
      .otp-validation-img {
        height: 80px;
        object-fit: contain;
      }
    }
    .fill-otp-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 3rem 0;
      @media screen and (max-width: 900px) {
        padding: 1.5rem 0;
      }
      .contact-us-link {
        font-size: 0.875rem;
      }
      .otp-label-container {
        display: flex;
        align-items: center;
        column-gap: 1rem;
        font-size: 0.875rem;
        font-weight: 600;
        @media screen and (max-width: 900px) {
          font-size: 0.75rem;
          column-gap: 1ch;
        }
        .otp-label {
          color: #7E7E7E;
        }
        .edit-otp-btn {
          color: #f1ae13;
          border: none;
          background-color: #fff;
          font-size: 0.875rem;
          font-weight: 600;
          cursor: pointer;
          @media screen and (max-width: 900px) {
            font-size: 0.75rem;
          }
        }
      }
      .otp-input {
        all: unset;
        position: relative;
        width: 250px;
        margin-top: 1rem;
        padding: 0.5rem 0;
        background-color: #EFEFEF;
        color: #7E7E7E;
        letter-spacing: 1ch;
        font-size: 1rem !important;
        font-weight: 600;
        text-align: center;
        -moz-appearance: none;
        @media screen and (max-width: 900px) {
          width: 175px;
        }
      }
      .otp-input::-webkit-outer-spin-button,
      .otp-input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      .otp-input[show-error="true"] {
        border: 1px solid red;
      }
      .otp-error-msg{
        display: none;
        position: relative;
        right: -125px;
        top: 5px;
        transform: translateX(-50%);
        color: red;
        font-size: 0.75rem;
      }
      .otp-error-msg[aria-hidden="true"] {
        display: block;
      }
      .resend-otp-container {
        margin-top: 3rem;
        @media screen and (max-width: 900px) {
          margin-top: 1.5rem;
        }
        .resend-otp-helper-text {
          display: flex;
          font-size: 0.75rem;
          color: #7E7E7E;
          column-gap: 1ch;
          .resend-timer {
            font-weight: 600;
            color: #f1ae13;
          }
        }
        .resend-otp-btn {
          color: #f1ae13;
          border: none;
          background-color: #fff;
          font-size: 0.75rem;
          font-weight: 600;
          text-decoration: underline;
          cursor: pointer;
        }
      }
      .verify-otp-btn {
        width: 250px;
        margin-top: 2rem;
        padding: 0.5rem;
        border: none;
        border-radius: 50px;
        color: #FFF;
        background-color: #f1ae13;
        font-size: 0.875rem;
        font-weight: 700;
        text-align: center;
        cursor: pointer;
        @media screen and (max-width: 900px) {
          width: 175px;
          margin-top: 1rem;
        }
      }
      .verify-otp-btn:disabled {
        background-color: #C4C4C4;
        cursor: not-allowed;
      }
    }
  }