.mlp-form-bg {
  background-image: url(../../../../Images/mlp-part-time/form-background-img.webp);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.main-mlp-form {
  display: flex;
  align-items: center;
  position: relative;
  // background: #ffffff;
  // width: 60%;
  width: 675px;
  height: 500px;
  
  @media screen and (max-width: 900px) {
    width: 90%;
    margin-top: 0em;
  }

  @media screen and (max-width: 600px) {
    width: 100%;
  }

  .bb-error-msg, .validation-err {
    color: red;
    @media screen and (max-width: 600px) {
      font-size: 11px;
    }
  }
  .mlp-form-header {
   
    margin: -2rem;
    margin-bottom: 0;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    background-color: #f1ae13;
    color: #ffffff;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    line-height: 116.4%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media screen and (max-width: 600px) {
      // top: 0;
      // width: 100%;
      margin: -2rem -0.7rem;
      margin-bottom: 0;
      padding: 1rem 2rem;
    }
  }

  .form-content {
    width: 73%;
    margin: auto;
    border-radius: 1rem;
    box-shadow: 4px 4px 25px rgb(0 0 0 / 10%);
    padding: 2rem;
    background-color: #ffffff;
    @media screen and (max-width: 600px) {
      margin-top: 1rem;
      width: 83%;
      padding: 1rem 0.7rem;
    }

    .form-input-phone {
      margin-top: 1.5rem;
    }

    .step{
      font-family: 'Abhaya Libre' !important;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 22.4px;
      text-align: center;
      color: #202020;
      @media screen and (max-width: 600px) {
        margin-top: 2em;
      }
    }
    .step-description{
      font-family: 'Abhaya Libre' !important;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 22.4px;
      text-align: center;
      color: #7E7E7E;
    }

    .form-input {
      input,
      select {
        padding: 0.5em;
        margin-bottom: 0.5em;
        width: 100%;
        box-sizing: border-box;
        color: #7e7e7e;
        font-size: 16px !important;
        border: solid;
        border-width: 1px;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-color: #7e7e7e;
        outline: none;
        background: none;
        outline: none;
        @media screen and (max-width: 600px) {
          font-size: 14px !important;
        }
      }
    }
    .form-referral {
      display: flex;
      align-items: baseline;
      justify-content: center;
      &.recommendation-box {
        border: 1px solid#C4C4C4;
        height: 47px;
        max-width: 395px;
        padding: 0px 5px;
        margin-top: 0.5em;
        .outer-block-referal-code {
          width: 100%;
          float: left;
          padding: 4px 10px 1px;
          @media screen and (max-width: 600px) {
            padding: 4px 1px 1px;
          
          }
        }
        input {
          border: none ;
          margin: auto ;
          padding: 10px 0px 0px ;
          color: #000 ;
          font-weight: 500;
          min-width: 70%;
          font-size: 13px !important;
          font-family: "Montserrat" !important;
          @media screen and (max-width: 600px) {
            font-size: 12px !important;
            padding-left: 5px;
          }
        }
        .referral-btn {
          box-shadow: none ;
          border-radius: 0px ;
          color: #1344F1 ;
          background: none ;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 140%;
          margin: auto 0px auto auto ;
          border-left: 1px solid #c4c4c4;
          min-height: 40px;
          font-family: "Plus Jakarta Sans", sans-serif !important;
          font-size: 13px;
          @media screen and (max-width: 600px) {
            font-size: 14px;
          }
        }
        .message-block {
            font-weight: 400;
            font-size: 8px;
            color: #08B076;
            &.error {
              color: #e40000 ;
            }
        }
      }
      .referral-btn {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 0.8em;
        color: white;
        align-items: center;
        font-weight: 600;
        font-size: 0.8em;
        color: white;
        border-radius: 5px;
        padding: 0.48rem 1rem;
        background: #f1ae13;
        cursor: pointer;
        box-shadow: 0px 1px 1px -1px rgb(0 0 0 / 20%),
          0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
          @media screen and (max-width: 600px) {
            padding: 0.48rem 0.5rem;
          }
        &.error{
          color: #c4c4c4;
        }
      }
    }
    .referral-success {
      color: #0ddda1;
      font-size: 0.8em;
      font-weight: 600;
      display: flex;
      align-items: center;
      img {
        width: 18px;
        margin-right: 3px;
      }
    }
    .referral-error {
      color: #ea5824;
      font-size: 0.7em;
      font-weight: 500;
    }

    // .spec-dates .date label:hover {
    //   background: white !important;
    //   border: initial !important;
    //   color: initial !important;
    // }

    .spec-dates .date input[name="specialisation"]:checked + label {
      background: rgba(19, 68, 241, 0.06);
      border: 0.25px solid #1344f1;
      color: #1344f1;
    }

    .spec-dates .date input[name="specialisation"]:disabled + label {
      cursor: not-allowed;
    }

    .spec-dates .date input[name="specialisation"]:disabled + label:hover {
      border: 0.25px solid transparent;
      color: inherit;
    }

    .spec-group {
      padding-bottom: 1rem;
      border-bottom: 1px solid #c4c4c4;
    }

    .slot-day{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15.7px;
      text-align: center;
      color: #000000;
      text-transform: uppercase;
      @media screen and (max-width: 600px) {
        font-size: 10px;
      }
    }
    .slot-time {
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 15.7px;
      text-align: center;
      color: #1344F1;
      margin: 0 .3em !important;
      @media screen and (max-width: 600px) {
        font-size: 11px;
      }
    }

    .slot-text{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      color: #606060;
      @media screen and (max-width: 600px) {
        font-size: 13px;
      }
    }
    .slot-group {
      display: block;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -o-user-select: none;

      @media screen and (max-width: 600px) {
        font-size: 0.8em;
      }

      .slot-dates {
        margin-top: 1em;

        .date {
          label {
            margin: initial;
            margin-right: 0.2em;
            padding: 0.5em;
          }
        }
      }
    }

    .spec-dates {
      width: 80% !important;
      margin-top: 1em;
      @media screen and (max-width: 600px) {
        width: 100% !important;
      }
      .date {
        width: 160px !important;
        label {
          padding: 0.8em 0.4em !important;
          margin: initial;
          margin-right: 1em;
          .spec-text {
            font-size: 0.8em !important;
          }
        }
      }
    }

    .slot-group .spec-dates {
      display: flex;
      margin-top: 0.3em;
      // margin-left: 0.5em;
      @media screen and (max-width: 600px) {
        margin-top: 0.7em;
        gap: 0.5rem;
        margin-left: 0;
      }
    }

    .spec-dates .date input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .spec-dates .date {
      /* border: 1px solid #c4c4c4; */
      max-width: 165px;
      padding: 0.5em 0.5em !important;
    }

    .spec-dates .date label {
      border: 1px solid #c4c4c4;
      padding: 0.5em 0;
      display: block;
      // margin-left: 1.5em;
      cursor: pointer;
      border-radius: 2px;
    }

    //for specialisations

    .optin-spec-container {
      color: #7e7e7e;
      display: flex;
      align-items: center;
      position: relative;
      padding-left: 30px;
      margin-bottom: 12px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      font-size: 11px;
      text-align: initial;

      .wrapper-check {
        display: flex;
        align-items: center;
        color: #1a4af1;
        font-weight: 600;
        font-size: 16px;
        margin-top: 4px;
        @media screen and (max-width: 600px) {
          // display: block;
          font-size: 14px;
        }
      }
    }

    .optin-spec-container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .optin-spec-checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      border: 1px solid #c4c4c42e;
      background-color: #eee;
    }

    /* When the checkbox is checked, add a blue background */
    .optin-spec-container input:checked ~ .optin-spec-checkmark {
      background-color: white;
      box-shadow: 0px 1px 1px -1px rgb(0 0 0 / 20%),
        0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .optin-spec-checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    .optin-spec-container input:checked ~ .optin-spec-checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .optin-spec-container .optin-spec-checkmark:after {
      left: 8px;
      top: 2px;
      width: 4px;
      height: 12px;
      border: solid #f1ae13;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    // .spec-dates .date label:hover {
    //   background: rgba(19, 68, 241, 0.06);
    //   border: 0.25px solid #1344f1;
    //   color: #1344f1;
    // }

    // .whatsapp_optin {

    // }
    .whatsapp {
      width: 120px;
      @media screen and (max-width: 600px) {
        width: 100px;
      }
    }

    .opt-out-text {
      display: block;
      color: #7e7e7e;
      font-size: 10px;
      padding-left: 30px;
      padding-bottom: 5px;
      // margin-top: -0.1em;
    }

    .optin-container {
      color: #7e7e7e;
      display: flex;
      align-items: center;
      position: relative;
      //padding-left: 30px;
      // margin-bottom: 12px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      font-size: 11px;
      text-align: initial;

      .wrapper-check {
        display: flex;
        align-items: center;
        font-family: Plus Jakarta Sans !important;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 133.4%;
        color: #7E7E7E;
        align-items: center;
        margin-top: -2px;
        //color: #232323;
        @media screen and (max-width: 600px) {
          margin-top: 2px;
          font-size: 14px !important;
        }
      }

      img {
        width: 110px !important;
        @media screen and (max-width: 600px) {
          display: inline-block;
          width: 80px !important;
        }
      }
    }

    .radio-option {
      display: flex;
    }
    .radio-input {
      appearance: none; /* Removes default radio button styling */
      width: 18px;
      height: 18px;
      border: 2px solid #4e4e4e; /* Default border color */
      border-radius: 50%;
      position: relative;
      cursor: pointer;
    }
    
    .radio-input {
      border-color: #d9d9d9; /* Border color when checked */
    }
    
    .radio-input:checked::before {
      content: '';
      width: 8px;
      height: 8px;
      background-color: #f1ae13; /* Dot color when checked */
      border-radius: 50%;
      position: absolute;
      top: 3.7px;
      left: 3.5px;
    }
    .optin-container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    .optin-checkmark {
      position: absolute;
      top: 0;
      left: 0;
      height: 25px;
      width: 25px;
      border: 1px solid #c4c4c42e;
      background-color: #eee;
    }

    /* When the checkbox is checked, add a blue background */
    .optin-container input:checked ~ .optin-checkmark {
      background-color: white;
      box-shadow: 0px 1px 1px -1px rgb(0 0 0 / 20%),
          0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);

    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .optin-checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    .optin-container input:checked ~ .optin-checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .optin-container .optin-checkmark:after {
      left: 8px;
      top: 2px;
      width: 4px;
      height: 12px;
      border: solid #f1ae13;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    .checkboxes label {
      .checkboxes{
        margin-top: 2em;
      }

    }
    .notice {
      font-size: 0.75em;
      text-align: center;
      color: #7e7e7e;
    }

    .alreadyExists {
      color: #ea5824;
      text-align: center;
      font-size: 0.9em;
      width: 90%;
      a {
        font-weight: 600;
        font-style: italic;
        text-decoration: underline;
        color: #1344f1;
      }
    }

    .submit-btn-helper-txt {
      margin-top: 1.7rem;
      font-size: 10px;
      text-align: center;
      color: #4E4E4E;
      @media screen and (max-width: 900px) {
        font-size: 14px;
      }
    }

    .mlp-submit {
      margin-top: 1.5rem;
      .submit-btn {
        cursor: pointer;
        // max-width: 335px;
        padding: 1em 0;
        margin: 0 auto;
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-weight: 600;
        font-size: 14px;
        text-transform: uppercase;
        text-align: center;
        border-radius: 100px;
        border: none !important;
        @media screen and (max-width: 900px) {
          padding: 0.8em 0.5em;
          max-width: 85%;
          max-width: 240px;
          display: flex;
          justify-content: center;
          margin: 0;
        }
      }

      .gray-bg {
        background: #c4c4c4;
      }

      .yellow-bg {
        background: #f1ae13;
      }
    }
    .mlp-radio-group {
      display: flex;
      color: #7e7e7e;
      font-size: 0.9em;
      align-items: center;

      @media screen and (max-width: 600px) {
        display: block;
      }

      input {
        width: initial;
        margin-bottom: initial;
      }

      .radio-group {
        display: flex;
        gap: 3rem;
        @media screen and (max-width: 600px) {
          margin-top: 0.7em;
          width: 45%;
        }
        .date {
          display: flex;
          align-items: center;

            input[type="radio"] {
              position: absolute;
              opacity: 0;
              + .radio-label {
                &:before {
                  content: '';
                  background: #f4f4f4;
                  border-radius: 100%;
                  border: 1px solid darken(#f4f4f4, 25%);
                  display: inline-block;
                  width: 1.1em;
                  height: 1.1em;
                  position: relative;
                  margin-right: 1em;
                  vertical-align: top;
                  cursor: pointer;
                  text-align: center;
                  transition: all 250ms ease;
                }
              }
              &:checked {
                + .radio-label {
                  &:before {
                    background-color: #f1ae13;
                    box-shadow: inset 0 0 0 4px #f4f4f4;
                  }
                }
              }
              &:focus {
                + .radio-label {
                  &:before {
                    outline: none;
                    border-color: #f1ae13;
                  }
                }
              }
              &:disabled {
                + .radio-label {
                  &:before {
                    box-shadow: inset 0 0 0 4px #f4f4f4;
                    border-color: darken(#f4f4f4, 25%);
                    background: darken(#f4f4f4, 25%);
                  }
                }
              }
              + .radio-label {
                &:empty {
                  &:before {
                    margin-right: 0;
                  }
                }
              }
            }
        }
        label {

          cursor: pointer;
          display: flex;
          font-size: 13px;
          font-weight: 300;
          color: #7e7e7e !important;
          font-weight: 400 !important;
          font-family: "Montserrat" !important;
          @media screen and (max-width: 600px) {
            color: black !important;
            font-size: 14px;
          }
        }
      }
    }
  }
  .help-desk {
    margin-top: 5em;

    .help-head {
      font-weight: 600;
      font-size: 0.9em;
      color: #7e7e7e;
      margin-bottom: 1em;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .right-line {
        width: 30%;
        height: 0.1em;
        background: #7e7e7e59;

        @media screen and (max-width: 600px) {
          width: 15%;
        }
      }

      .left-line {
        width: 2em;
        height: 0.1em;
        background: #7e7e7e59;
        width: 50%;
      }
    }
    .help-email {
      display: flex;
      justify-content: center;
      align-items: center;
      max-width: 275px;
      margin: 0 auto;
      color: #7e7e7e;
      padding: 0 10px !important;

      .help-mail-icon {
        margin-right: 1em;
      }

      .help-mail {
        font-size: 0.8em;
        text-align: initial;
        a {
          color: #1344f1;
          font-weight: 600;
          font-style: italic;
          text-decoration: underline;
          
        }
      }
     
    }
  }

  .thank-you-screen {
    padding: 3em 0;
    .thank-you-container {
      .submit-strip {
        background: #f1ae13;
        color: #ffffff;
        padding: 1em 0;
        font-weight: bold;
        margin-bottom: 2em;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 600px) {
          font-size: 0.8em;
        }

        .tick-div {
          margin-right: 0.5em;
        }
      }

      .greeting {
        font-size: 1em;
        line-height: 19px;
        text-align: center;
        color: #7e7e7e;

        @media screen and (max-width: 600px) {
          font-size: 0.9em;
        }

        .user-name {
          font-size: 1.5em;
          text-transform: capitalize;
          line-height: 26px;
          text-align: center;
          color: #f1ae13;
          margin-top: 0.5em;
          margin-bottom: 1.5em;
          font-weight: bold;
        }
      }

      .info {
        font-size: 1em;
        line-height: 19px;
        text-align: center;
        color: #7e7e7e;
        margin: 0 auto;
        margin-bottom: 19px;
        max-width: 23em;

        @media screen and (max-width: 600px) {
          font-size: 0.9em;
        }

        .choosen-date {
          font-weight: 600;
        }
      }

      .chat-with-us {
        width: 25em;
        margin: 3em auto;

        @media screen and (max-width: 600px) {
          width: 100%;
        }
      }

      .more-program {
        font-style: italic;
        @media screen and (max-width: 600px) {
          font-size: 0.9em;
        }

        a {
          color: #1344f1;
          font-weight: bold;
        }
      }
    }
  }

  .inEligible-container {
    padding: 3em 0;

    text-align: center;
    .inEligible-text {
      width: 55%;
      margin: 0 auto;
      margin-bottom: 2.5em;
      color: #404040;
      font-size: 0.9em;
      font-weight: 600;

      @media screen and (max-width: 600px) {
        width: 85%;
      }
    }

    .process-text,
    .instagram-text {
      width: 75%;
      margin: 0 auto;
      margin-bottom: 2.5em;
      color: #404040;
      font-size: 0.9em;

      @media screen and (max-width: 600px) {
        width: 90%;
      }
    }

    .process-divider {
      border: 0.5px solid #f1ae1336;
      margin: 4em auto;
      width: 85%;
    }

    .kraft-insta-link {
      margin: 0 auto;
      width: 23em;

      @media screen and (max-width: 600px) {
        width: 100%;
      }
    }
  }

}

.mtop-1{
  margin-top: 1em;
}

.mtop-1-25 {
  margin-top: 1.25rem;
}

.spec-dates .date {
  /* border: 1px solid #c4c4c4; */
  width: 160px ;
  padding: 0.5em 5em !important;
  font-size: 14px;
}

.radio-group {
  display: flex;
  gap: 1em;
  @media screen and (max-width: 800px) {
    color: #000 !important;
    margin-top: 0.5em;
  }
  input[type=radio] + .radio-label:before {
    @media screen and (max-width: 800px) {
      width: 13px;
      height: 13px;
      margin-right: 0.6em;
    }
  }
  .radio-label {
    display: flex;
    font-size: 16px;
    font-family: Plus Jakarta Sans !important;
    font-style: normal;
    font-weight: 500;
    line-height: 133.4%;
    color: #7E7E7E;
    @media screen and (max-width: 800px) {
      font-size: 14px;
    }
  }
}