.lp-certification{
  
    width: 100%;
    padding: 1em 6em;
    padding-top: 4em;
    @media screen and (max-width: 800px) {
        padding: 0;
        max-width: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .lp-c-sub-head {
        color: #f1ae13;
        font-family: Plus Jakarta Sans !important;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 133%;
        margin-bottom: 0.2em;
        @media screen and (max-width: 800px) {
            font-size: 16px;
            margin-top: 3em;
        }
    }
    .l-c-head {
        color: #505050;
            font-family: Plus Jakarta Sans !important;
            font-size: 28px;
            font-style: normal;
            font-weight: 600;
            line-height: 143%;
            @media screen and (max-width: 800px) {
                line-height: 130%;
                font-size: 20px;
                transform: translateY(5px);
            }
    }
    .lp-c-underline {
        height: 8px;
        width: 292px;
        margin-top: 0.4em;
        margin-bottom: 0.8em;
        @media screen and (max-width: 800px) {
            width: 253px;
            height: 5px;
            margin: 0;
        }
    }
    .l-c-desc {
        color: #505050;
            font-family: Plus Jakarta Sans !important;
            font-size: 16px;
            font-weight: 400;
            line-height: 143%;
            @media screen and (max-width: 800px) {
                display: none;
            }
    }
    .ptop-2 {
        padding-top: 2em;
    }
    .lp-p-flex {
        display: flex;
        justify-content: space-between;
        margin: 4em 0;
        max-width: 1250px;
        .otp-verification-container {
            width: 385px;
            @media screen and (max-width: 800px) {
                width: 335px;
            }
        }
        @media screen and (max-width: 800px) {
            flex-direction: column-reverse;
            margin: 2em 0;
            gap: 2em;
        }
        .lp-dmc-download {
            font-family: Plus Jakarta Sans !important;
            font-size: 16px;
            font-style: normal;
            font-weight: 800;
            color: white;
            width: 272px;
            height: 46px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #f1ae13;
            border-radius: 100px;
            margin-top: 3em;
            text-align: center;
            border: none;
            position: absolute;
            left: 23%;
            cursor: pointer;
            @media screen and (max-width: 800px) {
                left: 0%;
                position: relative;
                width: 269px;
                height: 34px;
                font-size: 14px;
                border-radius: 12px;
            }
        }
        .main-mlp-form {
            width: 380px;
            height: 565px;
            @media screen and (max-width: 800px) {
                width: 309px;
                height: 443px;
                margin: 0;
                margin-top: -3em;
                margin-bottom: -2em;
            }
            .form-content .mlp-submit .submit-btn {
                @media screen and (max-width: 800px) {
                    width: 100% !important;
                    max-width: 270px;
                    margin: 0;
                }
            }
            .form-input input {
                @media screen and (max-width: 800px) {
                    width: 100%;
                }
            }
            .mlp-form-header {
                height: 100px;
                width: 380px;
                margin: -1em;
                margin-bottom: 2em;
                @media screen and (max-width: 800px) {
                    margin-bottom: 1.45em;
                    width: 309px;
                    padding: 1em;
                    height: 64px;
                }
            }
           .form-content .form-input-phone{
               @media screen and (max-width: 800px) {
                margin-top: 0.6em !important;
               }
            }
            .wrapper-check {
                @media screen and (max-width: 800px) {
                    font-weight: 400 !important;
                }
            }
           
            .optin-container {
                margin-top: 4em;
                @media screen and (max-width: 800px) {
                    margin-top: 1.8em;
                }
            }
            .form-referral {
                display: none;
            }
            .mlp-radio-group {
                margin-bottom: 4em;
                .radio-group label{
                    font-size: 12px !important;
                }
                @media screen and (max-width: 800px) {
                    margin-bottom: 1em;
                }
            }
            .submit-btn-helper-txt {
                display: none;
            }
   
            .submit-btn-helper-txt-2 {
                color: #FF612C;
                font-family: Plus Jakarta Sans !important;
                font-size: 18px;
                font-weight: 600;
                line-height: 133%;
                text-align: center;
                margin-bottom: 1em;
                margin-top: 2em;
                @media screen and (max-width: 800px) {
                    margin-top: 0em;
                    font-size: 14px;
                    margin-top: 1.5em;
                }
            }
        }  
       
        .mlp-form-non-brand-head {
            color: #fff;
            font-family: Plus Jakarta Sans !important;
            font-size: 18px;
            font-weight: 700;
            line-height: 116%;
            @media screen and (max-width: 800px) {
                font-size: 16px;
            }
        }
        .mlp-form-non-brand-desc {
            color: #fff;
            font-family: Plus Jakarta Sans !important;
            font-size: 16px;
            font-weight: 700;
            line-height: 116%;
            margin-top: 0.4em;
            @media screen and (max-width: 800px) {
                margin: 0.4em 0;
                font-size: 12px;
            }
        }
        .submit-btn {
            width: 236px !important;
            height: 46px !important;
            text-transform: capitalize !important;
            font-weight: 800 !important;
            font-family: Plus Jakarta Sans !important;
            @media screen and (max-width: 800px) {
                width: 269px !important;
                height: 34px !important;
                border-radius: 12px !important;
                max-width: 100%;
            }
        }
        .form-content {
            width: 100%;
            height: auto;
            min-height: 100%;
            padding: 1em;
            margin-top: 0;
            @media screen and (max-width: 800px) {
                height: 95%;
                margin-top: 0;
            }
        }
        .mlp-form-bg {
            background: none;
        }
        .lp-dmc-card {
            width: 795px;
            height: 565px;
            border-radius: 10px;
            box-shadow: 4px 4px 25px rgba(0, 0, 0, 0.1);
            background: url(../../../../Images/landingPageImages/dmc-cardBg.webp);
            background-position: top right;
            background-repeat: no-repeat;
            background-size: 250px 200px;
            padding: 2em 1.2em 1em 3em;
            @media screen and (max-width: 800px) {
                box-shadow: 0px 4px 46px rgba(0, 0, 0, 0.22);
                background: none;
                padding: 1.5em 0.2em;
                width: 305px;
                height: auto;
                display: flex;
                flex-direction: column;
                align-items: center;
                background: linear-gradient(to bottom, transparent 0%, transparent calc(100% - 445px), #F3F0F0 calc(100% - 445px), #F3F0F0 100%);

            }
            .lp-dmc-head {
                color: #505050;
                font-family: Plus Jakarta Sans !important;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 133%;
                @media screen and (max-width: 800px) {
                    font-size: 18px;
                }
            }
            .lp-dmc-row {
                display: flex;
                justify-content: space-between;
               
                @media screen and (max-width: 800px) {
                    flex-direction: column-reverse;
                    width: 285px;
                    align-items: center;
                }
            
               
                .dmc-pc-img {
                    width: 307px;
                    height: 170px;
                     margin-top: 1em;
                     
                     @media screen and (max-width: 800px) {
                        margin-top: 0em;
                        margin-bottom: 1em;
                        width: 255px;
                        height: 140px;
                    }
                }
                .lp-dmc-li {
                    display: flex;
                    margin-top: 1em;
                    @media screen and (max-width: 800px) {
                        width: 261px;
                    }
                    .green-tick {
                        width: 33px;
                        height: 29px;
                        margin-right: 1em;
                    }
                  
                }
                .lp-dmc-li-title {
                    color: #f1ae13;
                    font-family: Plus Jakarta Sans !important;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 116%;
                    .lp-dmc-li-desc {
                        color: #505050;
                        font-family: Plus Jakarta Sans !important;
                        font-size: 16px;
                        font-style: normal;
                        line-height: 116%;
                        width: 366px;
                        font-weight: 500;
                        sup {
                            color: #505050;
                            font-weight: 500;
                            line-height: 116%;
                            font-family: Plus Jakarta Sans !important;
                            font-size: 8px;
                            margin-right: 3px;
                        }
                        @media screen and (max-width: 800px) {
                            font-size: 14px;
                            max-width: 230px;
                            font-weight: 400;
                        }
                    }
                    @media screen and (max-width: 800px) {
                        font-size: 14px;
                    }
                }
               
                .dmc-b-2 {
                    @media screen and (max-width: 800px) {
                        width: 270px;
                    }
                }
                .dmc-b-l {
                    padding-left: 3.2em;
                    border-left: 1.5px solid #e6e6e6;
                    @media screen and (max-width: 800px) {
                        padding-left: 0em;
                        border: none;
                        padding-top: 1.5em;
                        border-top: 1.5px solid #e6e6e6;
                        margin-top: 1.5em;
                        width: 270px;
                    }
                }
                .lp-dmc-hr {
                    width: 1px;
                    height: 145px;
                    background-color: #e6e6e6;
                    margin-right: 2em;
                    @media screen and (max-width: 800px) {
                        display: none;
                    }
                }
                .dmc-full-btn {
                    width: 92px;
                    height: 21px;
                    background-color: #3751FF;
                    color: white;
                    font-family: Plus Jakarta Sans !important;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50px;
                    font-size: 14px;
                    font-weight: 700;
                    @media screen and (max-width: 800px) {
                    font-size: 14px;
                    }
                }
                .lp-dmc-card-title-2 {
                    color: #505050;
                    font-family: Plus Jakarta Sans !important;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 133%;
                    margin-top: 0.5em;
                    margin-bottom: 1em;
                }
            }
            .lp-dmc-row:last-of-type {
                max-width: 700px;
                @media screen and (max-width: 800px) {
                    flex-direction: column;
                    margin-top: 1em;
                }
            }
        }
      
    }
}